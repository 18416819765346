<template>
  <div class="ReqStudent div-template" >
    <notification-popup  
    :dialog="notifSuccess"
    :contentMsg="$t('succesfully')"
    :headerMsg="$t('succesfully')"
    :nameBtnNo="$t('labelNo')"
    :nameBtnYes="$t('labelYes')"
    colorIcon = "success"
    typeModal="transaction"
    Icon="mdi-check-circle"
      :onHandlerNo="cancelSuccess"
    :onHandlerYes="buttonOke"/>


    <notification-popup 
    :dialog="notifFailed"
    :contentMsg="$t('failed')"
    :headerMsg="$t('failed')"
    :nameBtnNo="$t('labelNo')"
    :nameBtnYes="$t('labelYes')"
    colorIcon = "danger"
    typeModal="transaction"
    Icon="mdi-close-circle"
      :onHandlerNo="cancelFailed"
    :onHandlerYes="buttonOkeFailed"/>

    <v-container>
      <v-row justify="center">
        <v-col cols="6">
          <img
            class="img-premium"
            :src="require('@/assets/kyc/upgrade-premium-student.svg')"/>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" align="center" class="pa-0">
          <h4>{{$t('emailYourParents')}}</h4>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10" align="center" class="pa-0">
          <p class="mb-0">{{$t('descriptionReqStudent')}}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="email" 
            type="email"
            :label="$t('email')"
            outlined
            :rules="[rules.required]"
            prepend-inner-icon="mdi-email"
            :placeholder="$t('e.g.') + ' johnparent@mail.com'"
            :append-icon="isEmailValid()"
            :error="isDangerEmail()"
            :success="isSuccessEmail()"
            dense
            ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-container>
    </v-container> -->
    <div class="footer">
      <v-row justify="center">
        <v-col class="text-center" cols="8">
          <v-btn
            rounded
            color="primary"
            width="80%"
            block
            :disabled="buttonDone"
            @click="clickDone()"
          >
            {{ $t("upgradeNow") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
  
</template>

<script>
import NotificationPopup from "@/components/NotificationPopup.vue";

  export default {
    name: 'ReqStudent',
    
    components: {NotificationPopup, },
    data() {
      return {
        disabledBtn:"",
        notifSuccess:false,
        notifFailed:false,
        buttonDone:true,
        alignments: [
          'start',
          'center',
          'end',
        ],
        rules: {
          required: (value) => !!value || "Required.",
          min: (v) => v.length >= 6 || "Min 6 characters",
          emailMatch: () => `The email and password you entered don't match`,
        },
        email: "",
      }
    },
    watch:{

      email() {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ((this.email.length > 0 ) && (re.test(this.email) == true))  {
          this.buttonDone = false;
        } 
        else if ((this.email.length > 0 ) && (re.test(this.email) == false)){
          this.buttonDone =  true;
        }
        
        return this.buttonDone;
      },
    },
    methods:{
      isEmailValid() {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let data = ""
        if ((this.email.length > 0 ) && (re.test(this.email) == true))  {
          data = 'mdi-check';
        } 
        else if ((this.email.length > 0 ) && (re.test(this.email) == false)){
          data =  'mdi-alert-circle-outline';
        }
        return  data
      },
      isSuccessEmail() {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let data = false
        if ((this.email.length > 0 ) && (re.test(this.email) == true))  {
          data = false;
        } 
        else if ((this.email.length > 0 ) && (re.test(this.email) == false)){
          data =  true;
        }
        
        return data;
      },
      isDangerEmail() {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let data = false
        if ((this.email.length > 0 ) && (re.test(this.email) == true))  {
          data = false;
        } 
        else if ((this.email.length > 0 ) && (re.test(this.email) == false)){
          data =  true;
        }
        
        return data;
      },
      clickDone(){
        const payload = {
            parent_email:this.email,
        };

        this.$store
          .dispatch("childlinkage/create", payload)
          .then((response) => {
            if (response.status == true) {
              this.notifSuccess = true
            }else{
              this.notifFailed = true
            }
          })
          .catch((err) => {
            this.notifFailed = true
            console.log(err);
          });
      },
      clickDialogRemovePayment(){
        this.dialogRemovePayment= true
      },
      buttonOke(){
        this.notifSuccess = false
        if (this.notifSuccess == false) 
        this.$router.push({
          name: 'CompleteDataStart',
        });
      },
      buttonOkeFailed(){
        this.notifFailed = false
      },
      cancelFailed(){
        this.notifFailed=false;
      },
      cancelSuccess(){
        this.notifSuccess=false;
      },
    },
    mounted(){
    }
  }
</script>

<style lang="scss" scoped>
.div-template {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.label-minus {
  padding-left: -20px;
}

.h4-title{
  padding-top: 10px;
  padding-left: 10px;
}
.onHover{
  cursor: pointer;
}
.end{
  margin-right: 0px;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  text-align: center;
  background: #ffffff;
  mix-blend-mode: normal;
}
.btn-bottom{
  bottom:   0;
  margin-bottom: 15px;
}
</style>